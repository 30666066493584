import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isNil } from 'ramda'
import { browserHistory } from 'react-router'
import moment from 'moment-timezone'

import NewToolTip from '../NewToolTip'
import NewDatePicker from '../NewDatePicker'
import NewTabs from '../NewTabs'
import SortTab from '../SortTab'
import Dropdown from '../Dropdown'
import TimeStamp from '../Timestamp'

import * as S from './styles'

const NewHeader = ({
  title,
  returnText,
  returnUrl,
  returnAction,
  titleAction,
  sortTab,
  tabs,
  search,
  date,
  dropdown,
  timestamp,
  explanation,
  titleTooltip,
  renderAction,
}) => {
  function handleReturn() {
    return returnUrl ? browserHistory.push(returnUrl) : browserHistory.goBack()
  }

  function handleChange({ target }) {
    search.handleSearchQuery(target.value)
  }

  function renderTitle() {
    if (!isNil(titleTooltip)) {
      return (
        <NewToolTip {...titleTooltip}>
          <S.Title>{title}</S.Title>
        </NewToolTip>
      )
    }

    return <S.Title>{title}</S.Title>
  }

  return (
    <S.Container>
      <S.Content>
        <S.InnerContainer main>
          {returnText && (
            <S.Return onClick={returnAction || handleReturn}>
              {returnText}
            </S.Return>
          )}
          <S.InnerContainer fullWidth flex>
            {renderTitle()}
            {!isEmpty(titleAction) &&
              titleAction.map &&
              titleAction.map((action) => (
                <NewToolTip key={action.key} {...action.tooltip}>
                  {!action.isHidden && <S.TitleAction {...action} />}
                </NewToolTip>
              ))}
            {!isEmpty(renderAction) && renderAction()}
            {!isEmpty(timestamp) && (
              <S.Item>
                <TimeStamp {...timestamp} />
              </S.Item>
            )}
            {!isNil(explanation) && <S.Item explanation>{explanation}</S.Item>}
          </S.InnerContainer>
        </S.InnerContainer>

        <S.InnerContainer flex right>
          {!isEmpty(sortTab) && (
            <NewToolTip {...sortTab.tooltip}>
              <div>
                <SortTab {...sortTab} />
              </div>
            </NewToolTip>
          )}

          {!isEmpty(date) && (
            <div>
              <NewDatePicker darkMode {...date} />
            </div>
          )}

          {!isEmpty(tabs) &&
            (tabs.tooltip ? (
              <NewToolTip {...tabs.tooltip}>
                <div>
                  <NewTabs darkMode {...tabs} />
                </div>
              </NewToolTip>
            ) : (
              <NewTabs darkMode {...tabs} />
            ))}

          {!isEmpty(dropdown) && (
            <NewToolTip {...dropdown.tooltip}>
              <div>
                <Dropdown {...dropdown} readOnly />
              </div>
            </NewToolTip>
          )}

          {!isEmpty(search) && (
            <S.Search
              type="search"
              autoComplete="off"
              onChange={handleChange}
              value={search.value}
              placeholder={search.placeholder}
            />
          )}
        </S.InnerContainer>
      </S.Content>
    </S.Container>
  )
}

NewHeader.propTypes = {
  title: PropTypes.string.isRequired,
  returnText: PropTypes.string,
  returnUrl: PropTypes.string,
  titleAction: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      tooltip: PropTypes.shape({
        actionType: PropTypes.string,
        titleIcon: PropTypes.string,
        title: PropTypes.string,
        action: PropTypes.string,
        description: PropTypes.string,
      }),
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    }),
  ),
  sortTab: PropTypes.shape({
    handleSortButton: PropTypes.func,
    sortKey: PropTypes.string,
    sortKeyList: PropTypes.arrayOf(PropTypes.string),
    sortNotification: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    tooltip: PropTypes.shape({
      actionType: PropTypes.string,
      titleIcon: PropTypes.string,
      title: PropTypes.string,
      action: PropTypes.string,
      description: PropTypes.string,
    }),
    locked: PropTypes.arrayOf(PropTypes.number),
  }),
  search: PropTypes.shape({
    handleSearchQuery: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
  }),
  date: PropTypes.shape({
    timezone: PropTypes.string,
    startDate: PropTypes.instanceOf(moment),
    endDate: PropTypes.instanceOf(moment),
    onChange: PropTypes.func,
    untilYesterday: PropTypes.bool,
    selectedRange: PropTypes.string,
    readOnly: PropTypes.bool,
    monthPicker: PropTypes.bool,
    tooltip: PropTypes.shape({
      title: PropTypes.string,
      action: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  dropdown: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    onChange: PropTypes.func,
    searchable: PropTypes.bool,
    icon: PropTypes.node,
    tooltip: PropTypes.shape({
      actionType: PropTypes.string,
      titleIcon: PropTypes.string,
      title: PropTypes.string,
      action: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  timestamp: PropTypes.shape({
    updated_at: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    timezone: PropTypes.string,
  }),
  returnAction: PropTypes.func,
  tabs: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
        disabled: PropTypes.bool,
        notification: PropTypes.bool,
      }),
    ),
    activeItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    tooltip: PropTypes.shape({
      actionType: PropTypes.string,
      titleIcon: PropTypes.string,
      title: PropTypes.string,
      action: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  explanation: PropTypes.string,
  titleTooltip: PropTypes.shape({
    actionType: PropTypes.string,
    titleIcon: PropTypes.string,
    title: PropTypes.string,
    action: PropTypes.string,
    description: PropTypes.string,
  }),
  renderAction: PropTypes.func,
}

NewHeader.defaultProps = {
  returnText: undefined,
  returnUrl: undefined,
  titleAction: [],
  sortTab: {},
  search: {},
  date: {},
  dropdown: {},
  timestamp: {},
  returnAction: null,
  tabs: {},
  explanation: null,
  titleTooltip: null,
  renderAction: () => {},
}

export default NewHeader
