import { CancelToken, isCancel } from 'axios'
import Promise from 'bluebird'
import _ from 'lodash'
import debounce from 'debounce-promise'
import { debounceDelay } from '../../config'

const nullSource = {
  cancel: _.noop,
}

// TODO update this to not use the CancelToken api
// see: https://axios-http.com/docs/cancellation
const onlyLatestRequest = (createPromise) => {
  let lastestSource = nullSource
  const debouncedCreatePromise = debounce(createPromise, debounceDelay, {
    leading: true,
  })

  return async (...args) => {
    lastestSource.cancel()
    const source = CancelToken.source()
    lastestSource = source

    const promise = debouncedCreatePromise(...args, {
      cancelToken: source.token,
    })

    return new Promise((resolve, reject) => {
      const onSuccess = (values) => {
        if (source === lastestSource) {
          resolve(values)
        }
      }
      const onFailure = (error) => {
        if (!isCancel(error)) {
          reject(error)
        }
      }
      promise.then(onSuccess, onFailure)
    })
  }
}

export default onlyLatestRequest
