import { pipe, last, pluck, filter, toPairs, includes, defaultTo } from 'ramda'

const READ = 'READ'
const WRITE = 'WRITE'

const YARD_MAP = 'YARD_MAP'
const PEOPLE = 'PEOPLE'
const VEHICLE_UPSERT = 'VEHICLE_UPSERT'
const VEHICLE_STATUS = 'VEHICLE_STATUS'
const VEHICLE_YARD_CHANGE = 'VEHICLE_YARD_CHANGE'
const ENGINE_HOURS = 'ENGINE_HOURS'
const AUDITS = 'AUDITS'
const BILLING = 'BILLING'
const BILLING_ROLLBACK_STATUS_FINAL = 'BILLING_ROLLBACK_STATUS_FINAL'
const CRUD_YARD = 'CRUD_YARD'

const adminConfig = {
  [YARD_MAP]: [READ, WRITE],
  [PEOPLE]: [READ, WRITE],
  [VEHICLE_UPSERT]: [READ, WRITE],
  [VEHICLE_STATUS]: [READ, WRITE],
  [VEHICLE_YARD_CHANGE]: [READ, WRITE],
  [ENGINE_HOURS]: [READ, WRITE],
  [AUDITS]: [READ, WRITE],
  [BILLING]: [READ, WRITE],
  [BILLING_ROLLBACK_STATUS_FINAL]: [READ, WRITE],
  [CRUD_YARD]: [READ, WRITE],
}

const config = {
  SUPER_ADMIN: adminConfig,
  ADMIN: adminConfig,
  UPPER_MANAGER: {
    [YARD_MAP]: [READ, WRITE],
    [PEOPLE]: [READ, WRITE],
    [VEHICLE_UPSERT]: [READ],
    [VEHICLE_STATUS]: [READ, WRITE],
    [VEHICLE_YARD_CHANGE]: [READ, WRITE],
    [ENGINE_HOURS]: [READ, WRITE],
    [AUDITS]: [READ, WRITE],
    [BILLING]: [READ, WRITE],
    [BILLING_ROLLBACK_STATUS_FINAL]: [READ],
    [CRUD_YARD]: [READ],
  },
  MANAGER: {
    [YARD_MAP]: [READ, WRITE],
    [PEOPLE]: [READ, WRITE],
    [VEHICLE_UPSERT]: [READ],
    [VEHICLE_STATUS]: [READ, WRITE],
    [VEHICLE_YARD_CHANGE]: [READ, WRITE],
    [ENGINE_HOURS]: [READ, WRITE],
    [AUDITS]: [READ, WRITE],
    [BILLING]: [READ, WRITE],
    [BILLING_ROLLBACK_STATUS_FINAL]: [READ],
    [CRUD_YARD]: [READ],
  },
  SUPERVISOR: {
    [YARD_MAP]: [READ, WRITE],
    [PEOPLE]: [READ, WRITE],
    [VEHICLE_UPSERT]: [READ],
    [VEHICLE_STATUS]: [READ, WRITE],
    [VEHICLE_YARD_CHANGE]: [READ],
    [ENGINE_HOURS]: [READ, WRITE],
    [AUDITS]: [READ, WRITE],
    [BILLING]: [],
    [BILLING_ROLLBACK_STATUS_FINAL]: [],
    [CRUD_YARD]: [],
  },
  MECHANIC: {
    [YARD_MAP]: [READ, WRITE],
    [PEOPLE]: [READ],
    [VEHICLE_UPSERT]: [READ],
    [VEHICLE_STATUS]: [READ, WRITE],
    [VEHICLE_YARD_CHANGE]: [READ, WRITE],
    [ENGINE_HOURS]: [READ, WRITE],
    [AUDITS]: [READ, WRITE],
    [BILLING]: [],
    [BILLING_ROLLBACK_STATUS_FINAL]: [],
    [CRUD_YARD]: [],
  },
  CLERICAL: {
    [YARD_MAP]: [READ],
    [PEOPLE]: [READ],
    [VEHICLE_UPSERT]: [READ],
    [VEHICLE_STATUS]: [READ],
    [VEHICLE_YARD_CHANGE]: [READ],
    [ENGINE_HOURS]: [READ, WRITE],
    [AUDITS]: [],
    [BILLING]: [READ, WRITE],
    [BILLING_ROLLBACK_STATUS_FINAL]: [],
    [CRUD_YARD]: [READ],
  },
  CORPORATE_BILLER: {
    [YARD_MAP]: [READ],
    [PEOPLE]: [READ, WRITE],
    [VEHICLE_UPSERT]: [READ, WRITE],
    [VEHICLE_STATUS]: [READ, WRITE],
    [VEHICLE_YARD_CHANGE]: [READ, WRITE],
    [ENGINE_HOURS]: [READ, WRITE],
    [AUDITS]: [],
    [BILLING]: [READ, WRITE],
    [BILLING_ROLLBACK_STATUS_FINAL]: [READ, WRITE],
    [CRUD_YARD]: [READ],
  },
  DRIVER: {},
  GATE_INSPECTOR: {},
}

function getRoles(accessContext, accessType) {
  return pipe(
    toPairs,
    filter((configPair) =>
      includes(accessType, defaultTo([])(last(configPair)[accessContext])),
    ),
    pluck('0'),
  )(config)
}

export const YARD_MAP_WRITE_ROLES = getRoles(YARD_MAP, WRITE)
export const PEOPLE_WRITE_ROLES = getRoles(PEOPLE, WRITE)
export const VEHICLE_UPSERT_WRITE_ROLES = getRoles(VEHICLE_UPSERT, WRITE)
export const VEHICLE_STATUS_WRITE_ROLES = getRoles(VEHICLE_STATUS, WRITE)
export const VEHICLE_YARD_CHANGE_ROLES = getRoles(VEHICLE_YARD_CHANGE, WRITE)
export const ENGINE_HOURS_ROLES = getRoles(ENGINE_HOURS, WRITE)
export const AUDITS_WRITE_ROLES = getRoles(AUDITS, WRITE)
export const BILLING_WRITE_ROLES = getRoles(BILLING, WRITE)
export const BILLING_ROLLBACK_STATUS_FINAL_WRITE_ROLES = getRoles(
  BILLING_ROLLBACK_STATUS_FINAL,
  WRITE,
)
export const CRUD_YARD_WRITE_ROLES = getRoles(CRUD_YARD, WRITE)

export default config
